import React from "react";
import SEO from "components/seo";
import Button from "components/ui/button/Button";

const NotFoundPage = () => (
  <div style={{ pointerEvents: "auto", padding: "80px 40px 0 40px" }}>
    <SEO title="Page Not found" />
    <h1>NOT FOUND</h1>
    <h4 style={{ color: "#efdbdd" }}>Oh Noes.</h4>
    <p style={{ color: "#ced7fa" }}>
      You just found a page that doesn&#39;t exist... the sadness.
    </p>
    <Button to={"/"} rounded theme="primary">
      Back to Home
    </Button>
  </div>
);

export default NotFoundPage;
